import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { default as PrivateRoute } from 'PrivateRoute';
import { default as AdminPrivateRoute } from 'AdminPrivateRoute';
import ReactNotification from 'react-notifications-component';
import { GlobalStyles, ScrollToTop } from 'utils';
import 'antd/dist/antd.css';
import Analytics from 'react-router-ga';

// import { add, init, track } from '@amplitude/analytics-browser';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import {
  HomePage,
  ProjectPage,
  ProjectsPage,
  ProjectFormPage,
  HowItWorksPage,
  AboutUsPage,
  ProfilePage,
  MyProjectDetailsPage,
  ResetPasswordPage,
  StartProjectPage,
  ThankYouStartProject,
  LoginPage,
  ThankYouPage,
  SingUp,
  Datenschutz,
  Impressum,
  ANB,
  Fees,
  Legitimation,
  TransparencyAndTrustPage,
  ContactUsPage,
  Guidelines,
  FAQs,
  WelcomePage,
  CancelPage,
  SuccessPage,
  AdminLoginPage,
  AllProjects,
  SelectHomeProject,
  ViewProject,
  NewsletterPage,
  InvoicesPage,
  AddFundingDaysPage,
  CreateDraftPage,
  BookedGoodiesPage,
  AllDonations,
  ViewDonationDetails,
  AllCategory,
} from 'scenes';
import 'utils/fetchIntercept';
import CompletionScreen from 'scenes/CompletionScreen/CompletionScreen';
import PaymentPage from 'scenes/Donation/PaymentPage';
import DonationPage from 'scenes/Donation/DonationPage';
import ProjectPageTest from 'scenes/Project/ProjectPageTest';
import RecurringPayments from 'scenes/RecurringPayments/RecurringPayments';
import AdminRecurringPayments from 'scenes/AdminRecurringPayments/AdminRecurringPayments';
import ParticipantPage from 'scenes/ParticipantPage/ParticipantPage';
import ProjectCategoryView from 'scenes/ProjectCategoryView/ProjectCategoryView';
import ThankYouPageTicket from 'scenes/ThankYou/ThankYouPageTicket';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import LoggedInRoute from 'components/Protected';
import Locked from 'scenes/locked';
import EventsPage from 'scenes/Projects/EventsPage';
import { isAuthenticated } from 'utils/Authentication';
import './scenes/Project/components/project_details.css';
import './App.css';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import { add, init, track } from '@amplitude/analytics-browser';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

// const AMPLITUDE_API_KEY = 'e866d2f4ce86c5b0144b8f9cfa798cc1';

// const sessionReplayTracking = sessionReplayPlugin({
//   debugMode: true,
// });
// add(sessionReplayTracking);
// init(AMPLITUDE_API_KEY, {
//   defaultTracking: { sessions: true },
//   autocapture: { sessions: true },
// });
function App() {
  // useEffect(() => {
  //   track('Session Started');
  // }, []);
  const clientId =
    '854094021528-ndcukriu7kcde6ijnj51qkku6pune8m0.apps.googleusercontent.com';

  return (
    <div>
      <ReactNotification />
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <GoogleOAuthProvider clientId={clientId}>
          <Analytics id="UA-203767931-1">
            <Switch>
              <LoggedInRoute exact path="/" component={HomePage} />
              {/* <LoggedInRoute
              path="/projects/:category"
              component={ProjectsPage}
            /> */}
              <LoggedInRoute path="/projects" component={ProjectsPage} />
              <LoggedInRoute path="/events" component={EventsPage} />
              {/* <LowerCaseRedirectProject path="/project/:url" component={ProjectPageTest}  /> */}
              <LoggedInRoute
                exact
                path="/project/:url"
                component={ProjectPageTest}
              />
              <LoggedInRoute
                exact
                path="/project/:id/donation"
                component={DonationPage}
              />
              <LoggedInRoute
                exact
                path="/payment/:id/donation"
                component={PaymentPage}
              />
              <LoggedInRoute path="/about-us" component={AboutUsPage} />
              <LoggedInRoute path="/how-it-works" component={HowItWorksPage} />
              <LoggedInRoute
                path="/reset-password"
                component={ResetPasswordPage}
              />
              <Route path="/login" component={LoginPage} />
              <Route path="/protected" component={Locked} />
              <Route path="/register" component={SingUp} />
              <LoggedInRoute
                exact
                path="/thank-you/:url"
                component={ThankYouPage}
              />
              <LoggedInRoute
                exact
                path="/thank-you-page"
                component={ThankYouPage}
              />
              <LoggedInRoute
                exact
                path="/events-success"
                component={ThankYouPageTicket}
              />
              <LoggedInRoute path="/welcome" component={WelcomePage} />
              <LoggedInRoute path="/datenschutz" component={Datenschutz} />
              <LoggedInRoute path="/impressum" component={Impressum} />
              <LoggedInRoute path="/anb" component={ANB} />
              <LoggedInRoute path="/fees" component={Fees} />
              <LoggedInRoute path="/legitimation" component={Legitimation} />
              <LoggedInRoute path="/completion" component={CompletionScreen} />
              <LoggedInRoute
                path="/transparency-and-trust"
                component={TransparencyAndTrustPage}
              />

              <LoggedInRoute path="/contact-us" component={ContactUsPage} />
              <LoggedInRoute path="/guidelines" component={Guidelines} />
              <LoggedInRoute path="/faqs" component={FAQs} />
              <LoggedInRoute path="/cancel" component={CancelPage} />
              <Route path="/admin/login" component={AdminLoginPage} />
              {/* <Route exact path="/start-project" component={StartProjectPage} /> */}
              <LoggedInRoute
                exact
                path="/start-project"
                component={ProjectFormPage}
              />
              <AdminPrivateRoute
                path="/admin/view-project/:id"
                component={ViewProject}
              />
              <LoggedInRoute
                exact
                path="/start-project/thankyou"
                component={ThankYouStartProject}
              />
              <LoggedInRoute
                exact
                path="/recurring-payments"
                component={RecurringPayments}
              />

              <PrivateRoute path="/profile" component={ProfilePage} />

              <AdminPrivateRoute
                path="/admin/all-projects"
                component={AllProjects}
              />

              <AdminPrivateRoute
                path="/admin/all-donations"
                component={AllDonations}
              />
              <AdminPrivateRoute
                path="/admin/all-category"
                component={AllCategory}
              />
              <AdminPrivateRoute
                path="/admin/view-donation-detail/:id"
                component={ViewDonationDetails}
              />

              <AdminPrivateRoute
                path="/admin/select-projects"
                component={SelectHomeProject}
              />

              <AdminPrivateRoute
                path="/admin/newsletter"
                component={NewsletterPage}
              />
              <AdminPrivateRoute
                path="/admin/invoices"
                component={InvoicesPage}
              />
              <AdminPrivateRoute
                path="/admin/createdraft"
                component={CreateDraftPage}
              />
              <AdminPrivateRoute
                path="/admin/addfunding"
                component={AddFundingDaysPage}
              />
              <AdminPrivateRoute
                path="/admin/participants"
                component={ParticipantPage}
              />
              <AdminPrivateRoute
                path="/admin/bookedgoodies"
                component={BookedGoodiesPage}
              />

              <PrivateRoute
                exact
                path="/success-project"
                component={SuccessPage}
              />
              <PrivateRoute
                exact
                path="/view/:id"
                component={ProjectPageTest}
              />
              <PrivateRoute
                exact
                path="/start-project/:id"
                component={ProjectFormPage}
              />
              <PrivateRoute
                path="/my-project/:id"
                component={MyProjectDetailsPage}
              />
              <PrivateRoute
                exact
                path="/admin/recurring-payments"
                component={AdminRecurringPayments}
              />
              <LowerCaseRedirect
                path="/event/:categoryName"
                url="/event/"
                component={ProjectCategoryView}
              />
              <LowerCaseRedirect
                path="/:categoryName"
                url="/"
                component={ProjectCategoryView}
              />
            </Switch>
          </Analytics>
        </GoogleOAuthProvider>
      </Router>
    </div>
  );
}

export default App;

const LowerCaseRedirect = ({ component: Component, url, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthenticated()) return <Redirect to="/protected" />;
      const categoryName = props.match.params.categoryName;
      const lowerCaseCategoryName = categoryName.toLowerCase();

      if (categoryName !== lowerCaseCategoryName) {
        return <Redirect to={`${url}${lowerCaseCategoryName}`} />;
      }

      return <Component {...props} />;
    }}
  />
);

const LowerCaseRedirectProject = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const categoryName = props.match.params.url;
      const lowerCaseCategoryName = categoryName.toLowerCase();

      if (categoryName !== lowerCaseCategoryName) {
        console.log(lowerCaseCategoryName);
        return <Redirect to={`/project/${lowerCaseCategoryName}`} />;
      }

      return <Component {...props} />;
    }}
  />
);
