import React from 'react';
import { useLocation } from 'react-router-dom';

const DonationTable = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const title = queryParams?.get('title') ?? '';
  const amount = queryParams?.get('amount') ?? '';
  const tips = queryParams?.get('tips') ?? '';
  return (
    <table className="donation-table">
      <caption>Deine Auswahl:</caption>
      <tr>
        <th>Projekt</th>
        <th>{title}t</th>
      </tr>
      <tr>
        <td>Unterstützung</td>
        <td>{amount}</td>
      </tr>
      <tr>
        <td>Trinkgeld</td>
        <td>{tips}</td>
      </tr>
      <tr>
        <td>Summe</td>
        <td>{amount ? Number(amount) + Number(tips ?? '0') : ''}</td>
      </tr>
    </table>
  );
};

export default DonationTable;
