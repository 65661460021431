import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Colors } from 'utils';
import Support from "../../../images/support.svg"
const SupportComponentWrapper = styled.div`
  display: flex;
  gap:8px;
  align-items: center;
  margin-bottom: 15px;
  .image__wrapper {
    min-width: 50px;
    width:50px;
    margin-right: 10px;
    background:#f1f1f1;
    height:50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:75%;
    overflow:hidden;
    

    img {
      width: 100%;
      height: 30px;
      border-radius: 50%;
    }
  }
  .support__info {
    p {
      margin: 0;
      color: ${Colors.steel};
      line-height: 1;
      margin-bottom: 8px;
    }
    .name {
      font-size: 18px !important;
      font-weight: bold;
      color: ${Colors.black};
      margin-bottom: 10px;
      text-align:left
    }
  }
`;
function SupportComponent(props) {
  const {
    avatar,
    name,
    amount,
    anonymous,
    city,
    time,
    message,
  } = props;

  return (
    <>
    <SupportComponentWrapper>
      <div className="image__wrapper">
      {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17.841 15.659l.176.177.178-.177a2.25 2.25 0 0 1 3.182 3.182l-3.36 3.359-3.358-3.359a2.25 2.25 0 0 1 3.182-3.182zM12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 7.75-7.996L12 14zm0-13c3.315 0 6 2.685 6 6a5.998 5.998 0 0 1-5.775 5.996L12 13c-3.315 0-6-2.685-6-6a5.998 5.998 0 0 1 5.775-5.996L12 1zm0 2C9.79 3 8 4.79 8 7s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" fill="rgba(0,0,0,1)"/></svg> */}
      <img style={{marginRight:"0px"}} src={Support} alt="support-img" />
      </div>
      <div className="support__info">
        <p className="name">
          {anonymous ? 
            (city ? city : 'Eine herzliche Seele 🤗') 
            : name
          }
        </p>
        <p style={{textAlign:"left",fontWeight:"400" ,color:"rgb(109, 128, 131)"}}>
          <span>€{amount} </span> {time}
        </p>
        <p style={{textAlign:"left",fontWeight:"bolder",color:"#6D8083" ,wordBreak:"break-word"}}>
          <b>{message}</b>
        </p>
      </div>
      
    </SupportComponentWrapper>
    <div style={{height:"1px",background:"#c8c8c8",marginBottom:"15px"}}></div>
    </>
  );
}

SupportComponent.propTypes = {
  // avatar: PropTypes.string.isRequired,
  name: PropTypes.any,
  city: PropTypes.any,
  time: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  message: PropTypes.string,
  anonymous: PropTypes.any,
};

export default SupportComponent;
