import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Contact } from 'images';
import { Modal, PrimaryButton } from 'utils';
import ContactUsPage from 'scenes/ContactUsPage/ContactUsPage';
import { Popover } from 'antd';

const ContactModal = () => {
  const [showContact, setShowContact] = React.useState(false);
  const [profileModal, setProfileModal] = React.useState(false);

  const openProfileModal = () => {
    setShowContact(false);
    setProfileModal(true);
  };

  useEffect(() => {
    if (showContact) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showContact]);

  const isLargeDevice = window.matchMedia('(min-width: 768px)').matches;
  if (profileModal)
    return (
      <Modal
        title={
          <div style={{ padding: 20 }}>
            So erhältst du personalisierten Support für dein aktives Projekt:
          </div>
        }
        showModal={true}
        handelCloseModal={() => setProfileModal(false)}
      >
        <div>
          <ul style={{ listStyleType: 'decimal' }}>
            <li>
              Melde dich an und besuche dein Profil über den Menü-Button{' '}
              <Link to="/profile" onClick={() => setProfileModal(false)}>
                <b style={{ textDecoration: 'underline' }}>"Mein Profil"</b>
              </Link>
            </li>
            <li>
              Wähle das von dir gestartete Projekt aus, für das du Support
              benötigst.
            </li>
            <li>
              Klicke auf den Button <b>"Support anfragen"</b>, um
              personalisierten Support zu erhalten!
            </li>
          </ul>
          <div style={{ textAlign: 'center', marginTop: '40px' }}>
            <PrimaryButton onClick={() => setProfileModal(false)}>
              Besuche dein Profil
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    );
  if (isLargeDevice)
    return (
      <div
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          borderRadius: '50%',
          boxShadow: '0 8px 15px rgba(0, 0, 0, 0.3)',
          cursor: 'pointer',
          zIndex: 99,
        }}
      >
        <Popover
          content={
            showContact ? (
              <ContactUsPage
                formOnly
                closeModal={() => setShowContact(false)}
                openProfileModal={openProfileModal}
              />
            ) : null
          }
          title={
            <div style={{ fontSize: 20, width: 600 }}>
              Salam und willkommen! Hast du Fragen zu commonsplace? Unser Team
              hilft dir gerne weiter - einfach kontaktieren!
            </div>
          }
          trigger="click"
          placement="topRight"
          visible={showContact}
          onVisibleChange={() => setShowContact(!showContact)}
        >
          <span onClick={() => setShowContact(true)}>
            <img src={Contact} alt="" height={50} />
          </span>
        </Popover>
      </div>
    );
  return (
    <>
      {showContact && (
        <Modal
          left={isLargeDevice && '440px'}
          title={
            <div style={{ padding: 20, fontSize: 14 }}>
              Salam und willkommen! Hast du Fragen zu commonsplace? Unser Team
              hilft dir gerne weiter - einfach kontaktieren!
            </div>
          }
          showModal={showContact}
          handelCloseModal={() => setShowContact(false)}
        >
          <div style={{ marginBottom: 70 }}>
            <ContactUsPage
              formOnly
              closeModal={() => setShowContact(false)}
              openProfileModal={openProfileModal}
            />
          </div>
        </Modal>
      )}
      <span
        onClick={() => setShowContact(true)}
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          borderRadius: '50%',
          boxShadow: '0 8px 15px rgba(0, 0, 0, 0.3)',
          cursor: 'pointer',
          zIndex: 99,
        }}
      >
        <img src={Contact} alt="" height={50} />
      </span>
    </>
  );
};

export default ContactModal;
