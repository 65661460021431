import React from 'react';
import { Colors } from 'utils';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Button = styled.button`
  position: relative;
  z-index: 2;
  font-weight: bold;
  background: ${(props) =>
    !props.primary ? Colors.secondaryColor : Colors.white};
  border: 1px solid ${Colors.secondaryColor};
  color: ${(props) => (!props.primary ? Colors.white : Colors.secondaryColor)};
  font-size: 24px;
  width: 100%;
  border-radius: 35px;
  margin-top: 4px;
  margin-bottom: 4px;
`;
const DonationButton = ({ id }) => (
  <Link to={`/project/${id}/donation?donation=5`}>
    <Button>Unterstützen</Button>
  </Link>
);

export default DonationButton;
