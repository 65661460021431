import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, ProjectCategoryCard } from 'components';
import styled from '@emotion/styled';
import {
  Colors,
  H1,
  PrimaryButton,
  Container,
  SvgWrapper,
  Section,
  isMobile,
  device,
  isLabTop,
  isTablet,
} from 'utils';
import { Link, withRouter } from 'react-router-dom';
import { ShowMoreIcon, BeforeBackground, AfterBackground, Cross } from 'images';
import { ProjectsActions } from 'redux/actions';
import { Spin } from 'antd';
import CategoryMenu from 'components/layout/categoryMenu';
import { CategoryItemContainer } from 'components/ProjectCategoryCard/ProjectCategoryCard';
import AppSlider from 'utils/Slider';

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  @media ${device.allMobile} {
    display: block;
  }
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 65px;
    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
  }
`;

const RecommendedProjectsWrapper = styled.div`
  padding: 0 0;
  text-align: center;
  position: relative;
  margin-top: -5vh;

  h1 {
    position: relative;
    margin-bottom: 80px;
    font-weight: bold;
    @media ${device.laptop} {
      font-size: 32px;
    }
    &:after {
      content: '';
      width: 300px;
      height: 4px;
      background: ${Colors.secondaryColor};
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  & > a {
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
  }
  .show-more {
    font-weight: bold;
    font-size: 22px;
  }

  .show-more-wrapper {
    position: relative;
    .background {
      position: absolute;
      z-index: -1;
      width: 100%;
      top: -50%;
    }
  }
  .show-more-component {
    display: flex;
    align-items: center;
    @media ${device.allMobile} {
      display: block;
    }
    &__content {
      margin-left: 45px;
      text-align: left;
      @media ${device.allMobile} {
        text-align: center;
        margin-left: 0;
      }
      h2 {
        font-weight: bold;
        font-size: 45px;
        margin: 0;
        @media ${device.allMobile} {
          font-size: 25px;
        }
        @media ${device.laptop} {
          font-size: 32px;
        }
        @media ${device.tablet} {
          font-size: 28px;
        }
      }
      p {
        font-size: 30px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
        @media ${device.laptop} {
          font-size: 20px;
        }
        span {
          display: block;
          a {
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
const ProjectsWrapper = styled.div`
  position: relative;
  .container {
    background: #f9f9f9;
    margin-top: -8px;
  }

  .after-background {
    position: relative;
    margin-top: -5px;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
  column-gap: 40px;
  row-gap: 20px;

  > * {
    flex-grow: 1;
    max-width: calc(20% - 40px);
    flex-basis: calc(20% - 40px);
  }

  > :nth-child(9n + 6),
  > :nth-child(9n + 7),
  > :nth-child(9n + 8),
  > :nth-child(9n + 9) {
    flex-basis: calc(25% - 40px);
    max-width: calc(25% - 40px);
  }
`;

const FilterBtn = styled.div`
  position: relative;
  display: inline-flex;
  gap: 3px;
  padding: 2px 6px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 20px;
  align-self: center;
  font-size: 14px;
  background-color: #95bd51;
  color: #fff;
  border: none;

  img {
    filter: invert(1);
  }

  &:hover {
    border: 1px solid #95bd51;
    background-color: transparent;
    color: #95bd51;
    & img {
      filter: invert(0);
    }
  }
`;

const CategoryChip = styled.div`
  position: absolute;
  top: 85px;
  left: 36px;
  display: inline-flex;
  gap: 3px;
  padding: 2px 6px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 20px;
  // z-index: 2;
  align-self: center;
  font-size: 14px;
`;

export class RecommendedProjects extends Component {
  state = {
    showMore: true,
    perPage: 0,
    successfulPerPage: 0,
    allProjects: [],
    allProjectsIds: [],
    allSuccessfullProjects: [],
    allSuccessfullProjectsIds: [],
    closeMenu: null,
    openMenu: false,
    selectedCategory: '',
    loading: false,
    filteredProjects: [],
  };
  handleToggleMenu = () => {
    this.setState({
      openMenu: !this.state.openMenu,
      closeMenu: this.state.openMenu ? true : false,
    });
    const header = document.querySelector('header#main-header');
    const burger = header?.querySelector('ul.burger-menu');

    if (this.state.openMenu) {
      document.body.style.overflow = 'unset';
      if (burger) burger.style.display = 'block';
    } else {
      document.body.style.overflow = 'hidden';
      if (burger) burger.style.display = 'none';
    }
  };
  static propTypes = {
    getHomeProjects: PropTypes.func.isRequired,
    homeProjects: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  };
  componentDidMount() {
    const { getHomeProjects } = this.props;
    getHomeProjects();
  }

  render() {
    const {
      homeProjects,
      loading,
      categories,
      setCategoryTemp,
      tempCategory,
      noBG,
    } = this.props;
    const projects = isMobile()
      ? homeProjects.slice(0, 3)
      : homeProjects.slice(0, 6);
    return (
      <RecommendedProjectsWrapper>
        <ProjectsWrapper>
          {!noBG && (
            <div className="background">
              <SvgWrapper width="100%" height="100%">
                <BeforeBackground />
              </SvgWrapper>
            </div>
          )}
          <div className="container">
            <Container>
              <Section>
                <div>
                  <H1>Projekte</H1>
                </div>
                <CategoryMenu
                  openMenu={this.state.openMenu}
                  handleToggleMenu={this.handleToggleMenu}
                  closeMenu={this.state.closeMenu}
                  onClick={(category) => {
                    setCategoryTemp(category);
                    this.props.history.push('/projects');
                  }}
                  selected={''}
                />
                {isMobile() && (
                  <>
                    <CategoryChip
                      onClick={this.handleToggleMenu}
                      style={{
                        backgroundColor: '#95bd51',
                        color: '#fff',
                        border: 'none',
                      }}
                    >
                      Filter zeigen
                    </CategoryChip>
                  </>
                )}
                {!isMobile() && (
                  <>
                    <CategoryContainer>
                      {categories &&
                        categories.length > 0 &&
                        categories
                          .filter((cate) => cate.is_desktop !== false)
                          .map((project, index) => (
                            <ProjectCategoryCard
                              project={project}
                              key={`${JSON.stringify(project)}-${index}`}
                              data={categories}
                              onClick={(category) => {
                                setCategoryTemp(category);
                                this.props.history.push('/projects');
                              }}
                              isMatched={
                                tempCategory.category === project.category
                              }
                            />
                          ))}
                      {categories.filter((cate) => cate.is_desktop !== false)
                        .length > 8 && (
                        <CategoryItemContainer isMatched>
                          <div onClick={this.handleToggleMenu}>
                            <h2
                              title="Alle Filter"
                              style={{
                                fontSize: '14px',
                                margin: '0',
                                color: 'white',
                              }}
                            >
                              Alle Filter
                            </h2>
                          </div>
                        </CategoryItemContainer>
                      )}
                    </CategoryContainer>
                  </>
                )}
                <AppSlider
                  isLoading={loading}
                  heading=""
                  projects={projects ?? []}
                  chunkSize={4}
                  isMain
                  isShowMore
                />
                {/* <Spin spinning={loading}>
                  <CardsWrapper>
                    {projects.length > 0 &&
                      projects.map((project) => (
                        <Card project={project} key={JSON.stringify(project)} />
                      ))}
                  </CardsWrapper>
                </Spin>
                <Link to="/projects">
                  <PrimaryButton className="show-more">
                    Mehr anzeigen
                  </PrimaryButton>
                </Link> */}
              </Section>
            </Container>
          </div>
          <div className="after-background">
            <SvgWrapper width="100%" height="100%">
              <AfterBackground />
            </SvgWrapper>
          </div>
        </ProjectsWrapper>
        <Section className="show-more-wrapper">
          <Container>
            <div className="show-more-component">
              <div className="show-more-component__image">
                <SvgWrapper
                  width={
                    isMobile()
                      ? '100%'
                      : isTablet()
                      ? '270px'
                      : isLabTop()
                      ? '370px'
                      : '410px'
                  }
                  height={
                    isMobile()
                      ? 'auto'
                      : isTablet()
                      ? 'auto'
                      : isLabTop()
                      ? '270px'
                      : '310px'
                  }
                >
                  <ShowMoreIcon />
                </SvgWrapper>
              </div>
              <div className="show-more-component__content">
                <h3>Höchste Sicherheitsstandards</h3>
                <p>
                  Für commonsplace ist das Vertrauen der Community das
                  Wichtigste!{' '}
                  <span>
                    <Link to="/transparency-and-trust">Mehr lesen</Link>
                  </span>
                </p>
              </div>
            </div>
          </Container>
        </Section>
      </RecommendedProjectsWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, homeProjects, categories, tempCategory } = state.projects;

  return {
    loading,
    homeProjects,
    categories,
    tempCategory,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getHomeProjects: () => dispatch(ProjectsActions.getHomeProjects()),
  setCategoryTemp: (cat) => dispatch(ProjectsActions.setCategoryTemp(cat)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecommendedProjects)
);
