import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from '@emotion/styled';
import { BannerDownArrow } from 'images';
import { Card } from 'components';
import device from './device';
import Colors from './Theme';
import { Spin } from 'antd';
import isMobile from './isMobile';
import { Link } from 'react-router-dom';

const ArrowButton = styled.button`
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background: ${Colors.white};
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 30px 40px;
  @media ${device.allMobile}, ${device.tablet} {
    padding: 0;
    background: ${Colors.transparent};
    box-shadow: none;
    border-radius: 0;
  }
  .card {
    width: 32%;
    display: inline-block;
    text-align: center;
    margin-bottom: 20px;

    @media ${device.allMobile} {
      width: 90%;
      margin: auto;
      margin-bottom: 20px;
    }
    @media ${device.minLaptop} {
      width: 48%;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 0;
    }
    &:nth-of-type(3n + 2) {
      // margin: 0 15px 20px;
      @media ${device.tablet} {
        margin: 0 14px 20px;
      }
      @media ${device.allMobile} {
        width: 90%;
        margin: auto;
        margin-bottom: 20px;
      }
      @media ${device.minLaptop} {
        margin: auto;
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
`;

const Heading = styled.h2`
  text-align: left;
  font-size: 30px;
  color: #95bd51;
  margin: 0;
`;

const ShowMore = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #95bd51;
  background: none;
  margin-left: auto;
  @media ${device.allMobile} {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
`;

const AppSlider = ({
  isSuccessful,
  projects,
  heading,
  showMoreHandle,
  isShowMore,
  isLoading,
  chunkSize = 3,
  isMain,
}) => {
  const sliderRef = useRef();
  if ((!projects || !projects.length) && isLoading)
    return <Spin spinning={isLoading} />;
  if (!projects || !projects.length)
    return (
      <div>
        <h1 style={{ marginBottom: 20, marginTop: 40 }}>{heading}</h1>
        <div>No project found</div>
      </div>
    );
  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile() ? 'column' : 'row',
          gap: 8,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 40,
          marginBottom: 20,
          paddingRight: isMobile() ? 0 : 50,
        }}
      >
        <Heading>{heading}</Heading>
        {isShowMore && !isMobile() && (
          <>
            {isMain ? (
              <Link style={{ marginLeft: 'auto' }} to="/projects">
                <ShowMore>Mehr anzeigen</ShowMore>
              </Link>
            ) : (
              <ShowMore onClick={showMoreHandle}>Mehr anzeigen</ShowMore>
            )}
          </>
        )}
        <div
          style={{
            display: 'flex',
            gap: 8,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ArrowButton
            style={{
              transform: 'rotate(90deg)',
            }}
            onClick={() => sliderRef.current?.slickPrev()}
          >
            <BannerDownArrow width="25" height="25" />
          </ArrowButton>
          <ArrowButton
            style={{
              transform: 'rotate(270deg)',
            }}
            onClick={() => sliderRef.current?.slickNext()}
          >
            <BannerDownArrow width="25" height="25" />
          </ArrowButton>
        </div>
        {isShowMore && isMobile() && (
          <>
            {isMain ? (
              <Link to="/projects">
                <ShowMore>Mehr anzeigen</ShowMore>
              </Link>
            ) : (
              <ShowMore onClick={showMoreHandle}>Mehr anzeigen</ShowMore>
            )}
          </>
        )}
      </div>

      {isMobile() ? (
        <div style={{ width: '100%' }}>
          <Slider
            className="carousel-container"
            ref={sliderRef}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
          >
            {projects.map((project, index) => (
              <div key={`${JSON.stringify(project)}-${index}`}>
                <div style={{ width: '100%' }}>
                  <Card isSuccessful={isSuccessful} project={project} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            paddingLeft: isMain && !isMobile() ? 100 : 0,
            paddingRight: isMain && !isMobile() ? 100 : 0,
          }}
        >
          <Slider
            className="carousel-container"
            ref={sliderRef}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
          >
            {chunkArray(projects, chunkSize).map((px3, index) => (
              <div key={index}>
                <div style={{ display: 'flex', gap: 10, width: '100%' }}>
                  {px3.map((project, index) => (
                    <Card
                      isSuccessful={isSuccessful}
                      project={project}
                      key={`${JSON.stringify(project)}-${index}`}
                    />
                  ))}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </Spin>
  );
};

export default AppSlider;

function chunkArray(array, chunkSize = 3) {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}
