import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  PaymentActions,
  ProjectsActions,
  authActions,
  DonationsActions,
} from 'redux/actions';
import {
  Container,
  H1,
  Colors,
  PrimaryButton,
  isMobile,
  Modal,
  device,
  Section,
  H2,
} from 'utils';
import { Header, Footer } from 'components';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import config from 'config';
import { Spin, Table } from 'antd';
import { LoginForm } from 'components';
import '../../utils/Payment.css';
import ContactModal from 'scenes/Project/components/ContactModal';
const APP_URL = config.REACT_APP_URL;
const ThankYouWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 200px;
  h1 {
    font-weight: bold;
    color: ${Colors.GreenColor};
    & + p {
      font-size: 22px;
      color: ${Colors.GreenColor};
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    @media ${device.allMobile} {
      display: block;
    }
    a + button {
      margin-left: 20px;
      @media ${device.allMobile} {
        margin-left: 0;
      }
    }
  }
`;

const RecommendedProjectsWrapper = styled.div`
  padding: 0 0;
  text-align: center;
  position: relative;
  margin-top: -5vh;

  h1 {
    position: relative;
    margin-bottom: 80px;
    font-weight: bold;
    @media ${device.laptop} {
      font-size: 32px;
    }
    &:after {
      content: '';
      width: 300px;
      height: 4px;
      background: ${Colors.secondaryColor};
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  & > a {
    display: inline-block;
    text-decoration: none;
    margin: 20px auto 0;
    color: ${Colors.secondaryColor};
  }
  .show-more {
    font-weight: bold;
    font-size: 22px;
  }

  .show-more-wrapper {
    position: relative;
    .background {
      position: absolute;
      z-index: -1;
      width: 100%;
      top: -50%;
    }
  }
  .show-more-component {
    display: flex;
    align-items: center;
    @media ${device.allMobile} {
      display: block;
    }
    &__content {
      margin-left: 45px;
      text-align: left;
      @media ${device.allMobile} {
        text-align: center;
        margin-left: 0;
      }
      h2 {
        font-weight: bold;
        font-size: 45px;
        margin: 0;
        @media ${device.allMobile} {
          font-size: 25px;
        }
        @media ${device.laptop} {
          font-size: 32px;
        }
        @media ${device.tablet} {
          font-size: 28px;
        }
      }
      p {
        font-size: 30px;
        @media ${device.allMobile} {
          font-size: 16px;
        }
        @media ${device.laptop} {
          font-size: 20px;
        }
        span {
          display: block;
          a {
            text-decoration: underline;
            &:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;
const TableWrapper = styled.div`
  padding: 50px 0;
`;

class RecurringPayments extends Component {
  static propTypes = {
    resetDonation: PropTypes.func.isRequired,
  };
  state = {
    showModal: false,
    showLoginModal: false,
    selectedDonationId: '',
  };
  componentDidMount() {
    const { getMyRecurringPayments } = this.props;
    const userToken = localStorage.getItem('access-token');
    const client = localStorage.getItem('client');
    const user = localStorage.getItem('user');
    const isLoggedIn = !user?.user && userToken && client;
    if (!isLoggedIn) {
      this.setState({ showLoginModal: true });
    } else {
      getMyRecurringPayments();
    }
  }

  handelCloseModal = () => {
    this.setState({ showModal: false });
    document.body.style.overflowY = 'unset';
  };
  handelShowModal = () => {
    this.setState({ showModal: true });
    document.body.style.overflowY = 'hidden';
  };

  handelCloseLoginModal = () => {
    this.setState({ showLoginModal: false });
    document.body.style.overflowY = 'unset';
  };

  handleSelectedDonation = (id) => {
    this.setState({ selectedDonationId: id });
  };
  handleRequestCancelRecurringPayment = () => {
    const { cancelRecurringPayment, getMyRecurringPayments } = this.props;
    cancelRecurringPayment(this.state.selectedDonationId);
    getMyRecurringPayments();
  };

  render() {
    const { showModal, showLoginModal } = this.state;
    const { loading, recurringDonations } = this.props;
    console.log('recurringDonations', recurringDonations);
    const columns = [
      {
        title: 'ID',
        dataIndex: 'count',
        key: 'id',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Projekttitel',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Rhythmus',
        dataIndex: 'donation_type',
        key: 'donationtype',
        width: '300px',
      },
      {
        title: 'Spendenbetrag',
        dataIndex: 'amount',
        key: 'amount',
        width: '300px',
      },
      {
        title: 'Aktion',
        dataIndex: 'id',
        key: 'action',
        width: '200px',
        render: (id) => (
          <>
            <PrimaryButton
              minWidth={isMobile() ? '20%' : '100px'}
              onClick={() => {
                this.handelShowModal();
                this.handleSelectedDonation(id);
              }}
              primary={true}
            >
              stornieren
            </PrimaryButton>
          </>
        ),
      },
    ];

    return (
      <>
        <ContactModal />
        <div>
          <Header relativeHeader={true} />
          <Container>
            <ThankYouWrapper>
              <Spin spinning={loading}>
                {/* <H1>Deine wiederkehrenden Unterstützungen</H1>
                {
                  isMobile() ? (
                    <div className='table_header_recurring_payments'>
                      <p className='mobile_header'>
                        Projekttitel
                      </p>
                      <p className='mobile_header'>
                        Rhythmus
                      </p>
                      <p className='mobile_header'>
                        Spendenbetrag
                      </p>
                      <p className='mobile_header'>
                        Aktion
                      </p>
                    </div>
                  ) : (
                    <div className='table_header_recurring_payments'>
                      <H2>
                        Projekttitel
                      </H2>
                      <H2>
                        Rhythmus
                      </H2>
                      <H2>
                        Spendenbetrag
                      </H2>
                      <H2>
                        Aktion
                      </H2>
                    </div>
                  )
                }
                {
                  recurringDonations?.count > 0 ? recurringDonations?.donations?.map((donation) => {
                    return (
                      <div className='table-cell_recurring_payments'>
                        <a href={`${APP_URL}project/${donation.url}`} style={{ width: '11%', fontSize: "14px" }}>
                          {donation.title}
                        </a>
                        <div style={{ fontSize: "14px" }}>
                          {donation.donation_type}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          {donation.amount}
                        </div>
                        <PrimaryButton
                          minWidth={isMobile() ? '5%' : '170px'}
                          style={{fontSize: "12px"}}
                          onClick={() => { this.handelShowModal(); this.handleSelectedDonation(donation.id); }}
                          primary={true}
                        >
                          stornieren
                        </PrimaryButton>
                      </div>

                    )
                  }) : <H2>Keine wiederkehrenden Unterstützungen</H2>
                } */}
                <TableWrapper>
                  <Table
                    columns={columns}
                    dataSource={recurringDonations?.donations}
                    pagination={false}
                    loading={loading}
                  />
                </TableWrapper>
              </Spin>
            </ThankYouWrapper>
          </Container>
          <Footer />
        </div>
        <Modal showModal={showModal} handelCloseModal={this.handelCloseModal}>
          <div className="are_you_sure_modal">
            <H2>Bist du sicher?</H2>
          </div>
          <div className="are_you_sure_modal_btn">
            <PrimaryButton
              minWidth={isMobile() ? '20%' : '100px'}
              onClick={() => {
                this.handelCloseModal();
                this.handleRequestCancelRecurringPayment();
              }}
              primary={true}
            >
              Ja
            </PrimaryButton>
            <PrimaryButton
              minWidth={isMobile() ? '20%' : '100px'}
              onClick={() => {
                this.handelCloseModal();
              }}
              primary={true}
            >
              Nein
            </PrimaryButton>
          </div>
        </Modal>
        <Modal
          showModal={showLoginModal}
          handelCloseModal={this.handelCloseLoginModal}
        >
          <LoginForm
            navigate={false}
            handelCloseModal={this.handelCloseLoginModal}
            handleGetRecurringPayment={this.props.getMyRecurringPayments}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { recurringDonations, loading } = state.donations;
  return {
    recurringDonations,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyRecurringPayments: () =>
    dispatch(DonationsActions.getMyRecurringPayments()),
  cancelRecurringPayment: (id) =>
    dispatch(DonationsActions.cancelRecurringPayment(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecurringPayments);
