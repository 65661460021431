import React from 'react';
import { Colors, H1, PrimaryButton, isMobile } from 'utils';
import { CheckIcon, HomeBannerIcon } from 'images';
import { SvgWrapper, device, isLabTop, isTablet } from 'utils';
import styled from '@emotion/styled';
import HomeBannerImage from '../../../images/crowdfunding.png';
import '../../../utils/Payment.css';

const HomeBannerComponentWraper = styled.div`
  color: ${Colors.black};
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
  margin-top: 0px;
  @media ${device.allMobile} {
    display: block;
    padding: 0 20px;
  }
  @media ${device.tablet} {
    padding: 0 20px;
  }
  & > div {
    width: 50%;
    @media ${device.allMobile} {
      width: 100%;
    }
    &.home-banner__content {
      padding-left: 50px;
      @media ${device.laptop} {
        padding-left: 10px;
      }

      @media ${device.allMobile} {
        padding-left: 0;
      }
      h1 {
        margin: 0;
        text-align: left;
        font-size: 40px;
        font-weight: bold;
        @media ${device.allMobile} {
          text-align: center;
          font-size: 20px;
        }
        @media ${device.laptop} {
          font-size: 30px;
        }
        @media ${device.tablet} {
          font-size: 25px;
        }
        span {
          display: block;
          font-size: 25px;
          text-align: left;
          font-weight: normal;
          margin-bottom: 10px;
          @media ${device.allMobile} {
            text-align: center;
          }
          @media ${device.laptop} {
            font-size: 20px;
          }
          @media ${device.tablet} {
            font-size: 18px;
          }
        }
      }
      ul {
        padding: 0;
        list-style: none;
        margin-top: 65px;
        @media ${device.allMobile} {
          margin-top: 20px;
        }

        li {
          font-size: 25px;
          display: flex;
          align-items: center;
          line-height: 1;
          @media ${device.allMobile} {
            font-size: 17px;
            max-width: 100%;
            text-align: left;
          }
          @media ${device.laptop} {
            font-size: 20px;
          }
          @media ${device.tablet} {
            font-size: 18px;
          }
          span + span {
            margin-left: 30px;
            @media ${device.allMobile} {
              margin-left: 15px;
            }
            @media ${device.laptop} {
              margin-left: 15px;
            }
            @media ${device.tablet} {
              margin-left: 15px;
            }
          }
          & + li {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .home-image {
    text-align: right;
  }
  .home-footer {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    @media (max-height: 901px) {
      margin-top: 0;
    }
    @media ${device.laptop} {
      margin-top: 20px;
    }
    @media ${device.allMobile} {
      margin-top: 50px;
    }

    button {
      font-weight: bold;
      font-size: 20px;
    }
    p {
      font-size: 30px;
      font-weight: bold;
      @media (max-height: 901px) {
        margin-bottom: 10px;
      }
      @media ${device.laptop} {
        font-size: 24px;
      }
      @media ${device.tablet} {
        font-size: 22px;
      }
      span {
        font-weight: normal;
        display: block;
        font-size: 25px;
        @media ${device.laptop} {
          font-size: 20px;
        }
        @media ${device.tablet} {
          font-size: 18px;
        }
      }
    }
  }
  .disable-right-click {
    pointer-events: none;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .disable-right-click-mobile {
    pointer-events: none;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

function scrollDown() {
  const pageHeight = window.innerHeight - 50;
  window.scrollBy(0, pageHeight);
}

export default function HomeBannerComponent() {
  return (
    <HomeBannerComponentWraper>
      {isMobile() ? (
        <>
          <div className="home-image">
            <SvgWrapper
              width={isMobile() ? '100%' : '500px'}
              height={isMobile() ? '400px' : '500px'}
            >
              {/* <HomeBannerIcon /> */}
              <div
                className="disable-right-click-mobile"
                style={{ backgroundImage: `url("${HomeBannerImage}")` }}
              />
            </SvgWrapper>
          </div>
          <div className="home-banner__content">
            <h1>
              Die erste deutsch-muslimische Crowdfunding-Plattform
              <span style={{ fontSize: '18px' }}>
                Wo Muslime gemeinsam wachsen
              </span>
            </h1>
            {/* <ul>
              <li>
                <SvgWrapper
                  width={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                  height={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                >
                  <CheckIcon />
                </SvgWrapper>
                <span>Projekte ganz einfach starten</span>
              </li>
              <li>
                <SvgWrapper
                  width={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                  height={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                >
                  <CheckIcon />
                </SvgWrapper>
                <span>Mit der Community verbinden</span>
              </li>
              <li>
                <SvgWrapper
                  width={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                  height={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                >
                  <CheckIcon />
                </SvgWrapper>
                <span>Finanzierung sichern &amp; Traum verwirklichen</span>
              </li>
            </ul> */}
          </div>
          <div className="home-footer">
            <PrimaryButton primary={true} onClick={() => scrollDown()}>
              Jetzt Projekte entdecken
            </PrimaryButton>
          </div>
        </>
      ) : (
        <>
          <div className="home-banner__content">
            <h1>
              Die erste deutsch-muslimische Crowdfunding-Plattform
              <span>Wo Muslime gemeinsam wachsen</span>
            </h1>
            {/* <ul>
              <li>
                <SvgWrapper
                  width={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                  height={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                >
                  <CheckIcon />
                </SvgWrapper>
                <span>Projekte ganz einfach starten</span>
              </li>
              <li>
                <SvgWrapper
                  width={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                  height={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                >
                  <CheckIcon />
                </SvgWrapper>
                <span>Mit der Community verbinden</span>
              </li>
              <li>
                <SvgWrapper
                  width={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                  height={isMobile() ? '20px' : isLabTop() ? '30px' : '35px'}
                >
                  <CheckIcon />
                </SvgWrapper>
                <span>Finanzierung sichern &amp; Traum verwirklichen</span>
              </li>
            </ul> */}
          </div>
          <div className="home-image">
            <SvgWrapper
              width={
                isMobile()
                  ? '100%'
                  : isTablet()
                  ? '350px'
                  : isLabTop()
                  ? '400px'
                  : '500px'
              }
              height={
                isMobile()
                  ? 'auto'
                  : isTablet()
                  ? '300px'
                  : isLabTop()
                  ? '300px'
                  : '300px'
              }
            >
              <div
                className="disable-right-click"
                style={{ backgroundImage: `url("${HomeBannerImage}")` }}
              />
            </SvgWrapper>
          </div>
          <div className="home-footer">
            <p>
              commonsplace ist effektives und sicheres Crowdfunding!
              <span>
                Unterstütze deine Community oder realisiere dein Projekt
              </span>
            </p>
            <PrimaryButton
              style={{ marginBottom: 0 }}
              primary={true}
              onClick={() => scrollDown()}
            >
              Jetzt Projekte entdecken
            </PrimaryButton>
          </div>
        </>
      )}
    </HomeBannerComponentWraper>
  );
}
